@use 'partials' as *;

.componentStyle {
  visibility: hidden;

  &.visible {
    visibility: initial;
  }
}

.visible {
  opacity: 1;
  animation: fade-in 1.5s;
}

@keyframes fade-in {
  0% {
    opacity: 0.1;
    transform: translateY(size(120));
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@use 'partials' as *;

.sectionTitle {
  height: size(14);
  margin-bottom: size(20);
}

.lineupSectionTitle {
  height: size(14);
  margin-bottom: size(10);
}

.informationList {
  & > li {
    margin-bottom: size(2);
  }
}

.informationBlock {
  display: block;
  padding: size(10);
  background-color: var(--color-white);
}

.informationDate {
  font-size: size(10);
  color: var(--color-pinkishgray);
}

.informationTitle {
  font-size: size(12);
}

.sectionTwitter {
  margin-top: size(40);
}

// PC
@include media-min(pc) {

  .topKeywords {
    max-width: size(900);
  }

  .sectionTitle {
    height: size(27);
    margin-bottom: size(40);
  }

  .lineupSectionTitle {
    height: size(27);
    margin-bottom: size(0);
  }

  .informationList {
    & > li {
      margin-bottom: size(4);
    }
  }

  .informationBlock {
    padding: size(25) size(27);
  }

  .informationTitle {
    font-size: size(16);
  }

  .halfWidthSectionWrapper {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .halfWidthSection {
    width: 49.5%;
  }

  .sectionTwitter {
    margin-top: 0;
  }
}

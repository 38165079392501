@use 'partials' as *;

.componentStyle {
  display: grid; // flexからgridに変更
  grid-template-columns: 1fr; // SP用1列
  width: 100%;
  & > li:not(.dummy) {
    margin-bottom: 0.8vw;
  }
}

.itemBlock {
  display: block;
  width: 100%; 
  height: 100%;
  padding: size(10);
  background-color: var(--color-white);
}

.imageWrapper {
  width: 100%;
  
  & img {
    object-fit: cover;
    width: 100%;
    height: auto;
  }
}

.labelButtonWrapper {
  margin-top: size(10);
}

.labelButton {
  padding: size(4) size(12);
  font-size: size(12);
  font-weight: 600;
  color: var(--color-white);
  display: inline-block;
  letter-spacing: 0.05em; /* 文字間隔を少し広げる */
}

.bgLightBlue {
  background-color: var(--color-blue);
}

.bgPrimary {
  background-color: var(--color-primary);
}

.bgLightGray {
 background-color: lightgray;
}

.itemImage {
  width: inherit;
  height: inherit;
}

.itemStatus {
  position: absolute;
  right: size(0);
  bottom: size(-15);
  width: size(40);
  height: size(40);
}

.itemName {
  margin-top: size(10);
  font-size: size(12);

  @include text-ellipsis-multi(2);
}

.itemTags {
  margin-top: size(9);
  margin-bottom: size(10);
}

.tagItem {
  font-size: size(10);
}

.itemDate {
  display: flex;
  align-items: center;
  margin-top: size(10);
  font-size: size(10);
  color: var(--color-warmgray);
}

.estimatedDeliveryDate {
  display: flex;
  align-items: center;
  margin-top: size(10);
  font-size: size(10);
  color: var(--color-warmgray);
}

.dummy {
  height: 0;
  margin-bottom: 0;
}

.dummyBlock {
  display: block;
  width: 46vw;
  height: 100%;
  padding: 0;
  background-color: var(--color-white);
  opacity: 0;
}

// PC
@include media-min(pc) {
   .componentStyle {
        grid-template-columns: repeat(3, 1fr);
        gap: size(20);
    }
   
  .itemBlock {
    width: 100%;
    padding: size(20); 
  }

  .imageWrapper {
    & img {
        object-fit: contain;
        max-height: size(181);
    }
 }

 .labelButton {
    font-size: 0.8rem;
  }

  .itemImage {
    width: inherit;
    height: inherit;
  }

  .itemStatus {
    right: size(0);
    bottom: size(-30);
    width: size(60);
    height: size(60);
  }

  .labelButtonWrapper {
    margin-top: size(20);
  }

  .itemName {
    margin-top: size(10);
    font-size: size(16);
  }

  .itemTags {
    margin-top: size(18);
    margin-bottom: size(20);
  }

  .tagItem {
    font-size: size(10);
  }

  .itemDate {
    margin-top: size(10);
    font-size: size(12);
  }

  .estimatedDeliveryDate {
    font-size: size(12);
  }

  .dummyBlock {
    width: size(297);
  }
}

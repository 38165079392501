@use 'partials' as *;

.componentStyle {
  position: relative;
}

.splide {
  overflow: hidden;
}

.progressBg {
  background: var(--color-brownishgray);
}

.progress {
  height: size(2);
  background: var(--color-light);

  &::-webkit-progress-value {
    background-color: var(--color-light);
  }
}

.itemLink {
  display: block;
  width: 100%;
  height: 100%;
}

.itemImg {
  display: block;
  width: 100%;
  object-fit: cover;
}

.positions {
  position: absolute;
  right: size(10);
  bottom: size(-15);
  display: flex;
  gap: size(5);
  align-items: center;
}

.positionItem {
  width: size(5);
  height: size(5);
  background-color: var(--color-pinkishgray);

  &.active {
    background-color: var(--color-black);
  }
}

// PC
@include media-min(pc) {
  .positions {
    right: size(20);
    bottom: size(-25);
    gap: size(10);
  }

  .positionItem {
    width: size(10);
    height: size(10);
  }
}

@use 'partials' as *;

.componentStyle {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  user-select: none;
  border: none;

  img {
    display: block;
    width: 100%;
    height: 100%;
    max-height: inherit;
    color: transparent;
    text-indent: size(-9999);
    user-select: inherit;
  }
}

.defaultSize {
  position: absolute;
  max-width: inherit;
}

.isFreeSize {
  position: initial;
  object-fit: initial;
  max-width: 100%;
}

.isCircled {
  border-radius: 50%;
}

.objectFitCover {
  object-fit: cover;
}

.objectFitContain {
  object-fit: contain;
}